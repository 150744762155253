// 创建文件zh/index.js
const zh = {
  // 路由
  Index: '冠亚',
  Rolex: '劳力士',
  TuDor: '帝舵',
  IWC: '万国',
  CarTier: '卡地亚',
  Paneral: '沛纳海',
  Information: '资讯',
  Search: '搜索',
  Vip: '名峰会vip',
  Address: '销售店铺',
  Love: '您的最爱',
  Order: '预约',
  Login: '登录',
  Register: '注册',
  Series: '系列',
  Details: '详情',
  Modify: '修改',
  InformationDetails: '资讯详情',

  //
  AllBrands: '全部品牌',
  Service: '服务',
  Upkeep: '保养及检修',
  TermsForUsage: '使用条款',
  PrivacyPolicy: '隐私声明',
  vipArea: '名峰会vip专区',
  ReservationService: '预约服务',
  Appellation: '称呼',
  Mr: '先生',
  Mrs: '夫人',
  Ms: '小姐',
  FirstName: '名字',
  lastName: '姓氏',
  Surname: '姓',
  FirstName1: '名',
  Phone: '电话',
  Email: '电子邮箱',
  Time: '预约时间',
  Store: '预约门店',
  Submit: '提交预约',
  Nationality: '国籍',
  AgeGroup: '年龄组别',
  YouContact: '联络电话',
  Residence: '住宅',
  MailingAddress: '通讯地址',
  Country: '国家',
  City: '城市',
  Province: '省份',
  AddressLine: '地址',
  PostalCode: '邮政编码',
  Other: '其他',
  SelectActivity: '以下哪种贵宾活动客户比较感兴趣(可多选)',
  WatchSalesPromotion: 'VIP腕表特卖会',
  NewWatchPressEvent: '腕表新品发布会',
  YachtClub: '游艇会',
  WineTasting: '品酒会',
  Concert: '音乐会',
  NewCarTestDrive: '名车试驾',
  Golf: '高尔夫活动',
  Tennis: '网球活动',
  CookingCourse: '烹饪课程',
  YogaCourse: '瑜伽课程',
  Others: '其它',
  BusWatch: '平均每年购买几只手表',
  LessThan: '少于1只',
  OrMore: '6只或以上',
  Purpose: '购买手表的用途(可多选)',
  Gift: '礼物送人',
  FashionAccessories: '搭配服饰',
  SocialNetworkNeeded: '交际需求',
  NewReleaseProduct: '热衷新品',
  WatchCollection: '收藏鉴赏',
  Understand: '如何知道冠亚名表城',
  WalkedPast: '经过店铺',
  Advertisement: '平面广告',
  Internet: '互联网',
  Referral: '朋友推荐',
  ShoppedHereBefore: '曾于此购物',

  More: '查看更多',
  WatchesRecommended: '腕表推荐',
  BrandRecommend: '精品推荐',
  BuyWatch: '选购腕表',
  IMG: '图片',
  All: '全部',
  MyWatches: '我的腕表清单',
  SavedWatches: '已保存的腕表',
  PurchaseRecord: '我的购买记录',
  WatchBrand: '手表品牌',
  Account: '账号',
  Verification: '验证码',
  ExploreMore: '探索更多',
  Case: '表壳',
  Movement: '机芯',
  Features: '特点',
  Explore: '探索',
  ContinueExplore: '继续探索',
  Name: '姓名',
  CellPhone: '手机号码',
  DateBirth: '出生日期',
  Job: '工作',
  Hobby: '爱好(可多选)',
  Sumbit: '提交',
  Points: '积分',
  CardNumber: '卡号',
  ModifyData: '修改资料',
  LogOut: '退出登录',
  CurrentTime: '当前时间',
  Type: '型号',
  Quantity: '数量',
  Money: '金额',
  ConsumerShop: '消费店铺',
  ConsumptionTime: '消费时间',
  New: '新款',
  Home: '主页',
  InformationHome: '资讯主页',
  seconds: '秒',
  Message: '留言',
  Refresh: '刷新',

  // 语句翻译
  PleaseEnterYourSearch: '请输入搜索内容',
  ScanQRCodes: '扫码关注微信公众号',
  CustomerService: '感谢您联络客户服务',
  PleaseSelectStore: '请选择预约门店',
  ThankYou: '谢谢',
  ReservationSuccess: '预约成功，感谢您对于冠亚名表城的支持',
  RecoverMessage: '我们的代表将细阅留言，并尽快回复。',
  SendVerification: '发送验证码',
  DiscoverFamily: '探索腕表系列',
  ViewWatches: '浏览腕表',
  NoImages: '当前没有设置图片',
  WatchSeries: '浏览腕表系列',
  AddWishlist: '添加至心愿单',
  Unadd: '取消添加',
  LoginSuccessful: '登录成功!',
  RegisteredSuccessfully: '注册成功',
  ModifiedSuccessfully: '修改成功',
  RefreshSuccessfully: '刷新成功',
  AlreadyMember: '已是会员',
  NewUser: '新用户注册',
  LoginExpired: '登录过期，请重新登录',
  PersonalInformationModification: '个人信息修改',
  NoData: '暂无数据',
  TechnicalDetails: '技术细节',
  NoFound: '没有找到相关腕表',
  NotBrandFound: '在该品牌中没有找到相关腕表',
  RequestTimedOut: '请求超时，请重试',
  ServerError: '服务器错误',
  RequestFailed: '请求失败，请检查网络',
  JoinGuanYa: '本人愿意加入冠亚会员计划，并且已阅读、理解并同意遵守',
  MembershipAgreement: '《冠亚会员计划条款和细则》',
  Notice: '须知',
  NoticeMessage:
    '(1)本公司已明确告知且阁下(或阁下的监护人)明确同意本公司使用阁下之个人信息 ， 以(i)为阁下提供服务(包括履行订购及维修、提供售后服务、传递服务相关的通信(如确认订单及处理阁下之查询和要求)；(ii)为阁下发送营销信息。阁下个人信息包括姓名 、性别、联系地址、身份证号码、护照号码、电话号码、交易信息、银行卡号、支付 宝账户等个人信息。阁下（或阁下的监护人)授权本公司比对阁下提供之资料与收集所 得有关阁下之其他资料，以作查核之用。如本公司在比对后认为存在或可能存在虚假 、伪造、盗用、冒用及任何不真实、不合法或无效的情况，可不予核准使用会员卡或 暂停、取消会员卡。(2)阁下(或阁下的监护人)特此明确、单独同意本公司将有关阁下 之账户信息披露予: (i) 由本公司聘用兼有良好信誉之第三者，以向本公司提供与客户账户之操作及账户服务之市场推广有关之行政服务；(ii)冠亚属下之其他公司或其于世界 各地之被许可人(包括但不限于向产品维修机构提供为维修产品所需的必要的信息)； 及(iii)其名称或标志出现在会员卡上之任何第三者。此等数据转移对确保此服务获得国 际认可及有效提供会员卡会员服务及对由冠亚所提供之其他货品及服务之推广是必需 的。阁下(或阁下的监护人)授权本公司运用与阁下账户有关之资料作市场推广之用，包括与本公司选定之业务伙伴交换阁下非财务上之数据。(3)阁下(或阁下的监护人)有权 随时要求查阅本公司持有有关阁下或阁下账户的信息，以及更新及改正该等信息。本 公司保留权利征收适度之收费，以弥补依从有关要求之费用。上述要求应以书面做出 ， 并送交给本公司。',
  Cancel: '取消',
  Confirm: '同意',

  Please1: '请输入名字',
  Please2: '请输入姓氏',
  Please3: '请选择地区',
  Please4: '请输入手机号码',
  Please5: '请输入有效的手机号',
  Please6: '请输入有效的电子邮件地址',
  Please7: '请输入验证码',
  Please8: '请选择出生日期',
  Please9: '请输入其他内容',
  Please10: '请输入姓名',
  Please11: '请先勾选同意协议',
  Please12: '请输入内容',
  Please13: '请选择',
  Please14: '请输入国籍',
}
export default zh
