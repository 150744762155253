<template>
  <div class="header">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/img/logo.png" alt="Logo" />
      </router-link>
    </div>
    <!-- 左侧 -->
    <div class="left">
      <!-- <router-link to="/tudor">
          <img src="@/assets/img/Tudor Logo.jpg" alt="Icon Logo" />
        </router-link> -->
      <!-- <div>中文</div> -->

      <div>
        <el-dropdown @command="setLanguage" trigger="click">
          <span class="el-dropdown-link">
            {{ languageType }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">中文</el-dropdown-item>
            <el-dropdown-item command="en">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!-- 右侧 -->
    <div class="right">
      <div class="hidden-md-and-down">
        <ul class="nav-right">
          <li>
            <transition-group name="slide">
              <i class="el-icon-search" @click="toggleEdit" key="searchIcon"></i>
              <span @click="toggleEdit" v-if="!isEditing" key="search">{{ $t('Search') }}</span>
              <input
                v-else
                type="text"
                v-model="search"
                @keyup.enter="onSearch"
                class="TeShu"
                ref="seachInput"
                key="searchInput" />
              <span v-if="isEditing" key="search" @click="onSearch">{{ $t('Search') }}</span>
            </transition-group>
          </li>

          <router-link v-for="(item, index) in navItems" :key="index" :to="item.router">
            <li>
              <i :class="item.iconClass" />
              {{ item.name }}
            </li>
          </router-link>
        </ul>
      </div>

      <!-- <div class="hidden-lg-and-up">
        <router-link to="/address">销售店铺</router-link>
      </div> -->
      <!-- <router-link to="/rolex/w?apikey=202044f52f547ae44b2f82d9671967f0">
          <img src="@/assets/img/Iconlogo.jpg" alt="Icon Logo" />
        </router-link> -->
    </div>
  </div>
</template>

<script>
import { changeLanguage } from '@/utils/api'
export default {
  name: 'HeaderView',
  data() {
    return {
      navItems: [
        { iconClass: 'el-icon-s-custom', name: this.$t('Vip'), router: '/personage' },
        { iconClass: 'iconfont icon-weizhi', name: this.$t('Address'), router: '/address' },
        { iconClass: 'iconfont icon-aixin', name: this.$t('Love'), router: '/love' },
        { iconClass: 'iconfont icon-shijian', name: this.$t('Order'), router: '/order' },
      ],
      isEditing: false,
      search: '',
      language: localStorage.getItem('lang') || 'zh',
    }
  },
  computed: {
    languageType() {
      return this.language === 'zh' ? '中文' : 'English'
    },
  },

  methods: {
    // 翻译
    setLanguage(val) {
      // 防止相同语言还刷新
      if (this.language === val) return

      this.language = val
      this.$i18n.locale = this.language
      localStorage.setItem('lang', this.language)
      this.changeLanguage()

      // 刷新页面(js文件不会无感刷新)
      window.location.reload()
    },

    // 切换语言,提示后端
    changeLanguage() {
      changeLanguage({ lang: this.language }).then()
    },

    // 点击搜索
    toggleEdit() {
      this.isEditing = !this.isEditing
      if (this.isEditing) {
        this.$nextTick(() => {
          this.$refs.seachInput.focus()
        })
      }
    },
    // 搜索
    onSearch() {
      console.log('Searching for:', this.search)
      if (this.search === '') {
        this.$message.warning(this.$t('PleaseEnterYourSearch'))
        return
      }

      // 检查当前路径是否为 /sort
      if (this.$route.path !== '/sort') {
        this.$router.push({ path: '/sort', query: { search: this.search } })
      } else {
        this.$router.replace({ path: '/sort', query: { search: this.search } })
        this.$emit('seach', this.search)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 80%;
  margin: 0 auto;
  background-color: white;
  position: relative;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  .logo {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 8vh;
    }
  }

  .left {
    position: absolute;
    top: 50%;
    left: -1vw;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .right {
    position: absolute;
    top: 50%;
    right: -1vw;
    transform: translateY(-50%);
  }

  // 右侧
  .nav-right {
    display: flex;
    color: #a2a781;
    li {
      padding: 0px 0px 0px 10px;
      font-size: 0.8rem;
      i {
        font-size: 13px;
        margin-right: 5px;
      }
    }
  }

  // 搜索框
  .TeShu {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid black;
    // caret-color: white; /* 设置光标颜色为白色 */
    outline: none;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: opacity 0.5s ease;
  }
  .slide-enter,
  .slide-leave-active {
    opacity: 0;
    transform: translateX(20px);
  }

  .slide-move {
    transition: all 0.5s;
  }

  .slide-leave-active {
    position: absolute;
  }
}
</style>
