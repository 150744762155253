<template>
  <div class="main sticky">
    <Header @seach="seachFn" />
    <div class="navBox">
      <div>
        <!-- pc端 -->
        <ul class="nav hidden-md-and-down">
          <router-link
            v-for="(item, index) in navList"
            :key="index"
            :to="item.router"
            :class="{ active: activeIndex == index }">
            <li>
              {{ item.name }}
              <!-- <span class="separator" v-if="index < navList.length - 1"></span> -->
            </li>
          </router-link>
        </ul>

        <!-- 移动端 -->
        <el-dropdown trigger="click" class="hidden-lg-and-up">
          <span class="el-dropdown-link">
            {{ currentItemName }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
            <router-link v-for="(item, index) in dataList" :key="index" :to="item.router" custom>
              <el-dropdown-item>
                <i :class="item.icon"></i>
                {{ item.name }}
              </el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="hidden-lg-and-up" v-if="!isEditing" style="font-size: 0.8rem">
        <router-link to="/address">{{ $t('Address') }}</router-link>
      </div>

      <!-- 移动端搜索 -->
      <div class="hidden-lg-and-up">
        <ul class="nav-right">
          <li>
            <transition-group name="slide">
              <i class="el-icon-search" @click="toggleEdit" key="searchIcon"></i>
              <span @click="toggleEdit" v-if="!isEditing" key="search">{{ $t('Search') }}</span>
              <input
                v-else
                type="text"
                v-model="search"
                @keyup.enter="onSearch"
                class="TeShu"
                ref="seachInput"
                key="searchInput" />
              <span v-if="isEditing" key="search" @click="onSearch">{{ $t('Search') }}</span>
            </transition-group>
          </li>
        </ul>
      </div>
    </div>
    <PageHeader :content="content" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import PageHeader from '@/components/PageHeader.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'NaverView',
  components: {
    Header,
    PageHeader,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      navList: [
        { name: this.$t('Index'), router: '/' },
        { name: this.$t('Rolex'), router: '/rolex/rolex' },
        { name: this.$t('TuDor'), router: '/tudor' },
        { name: this.$t('CarTier'), router: '/cartier' },
        { name: this.$t('IWC'), router: '/iwc' },
        { name: this.$t('Paneral'), router: '/paneral' },
        { name: this.$t('Information'), router: '/information' },
      ],
      dataList: [
        { name: this.$t('Index'), router: '/' },
        { name: this.$t('Rolex'), router: '/rolex/rolex' },
        { name: this.$t('TuDor'), router: '/tudor' },
        { name: this.$t('CarTier'), router: '/cartier' },
        { name: this.$t('IWC'), router: '/iwc' },
        { name: this.$t('Paneral'), router: '/paneral' },
        { name: this.$t('Information'), router: '/information' },
        { iconClass: 'el-icon-s-custom', name: this.$t('Vip'), router: '/personage' },
        { iconClass: 'iconfont icon-weizhi', name: this.$t('Address'), router: '/address' },
        { iconClass: 'iconfont icon-aixin', name: this.$t('Love'), router: '/love' },
        { iconClass: 'iconfont icon-shijian', name: this.$t('Order'), router: '/order' },
      ],
      isEditing: false,
      search: '',
      currentItemName: this.$t('Index'),
    }
  },
  computed: {
    ...mapState(['activeIndex', 'brand']), // 从 Vuex 中获取
  },
  watch: {
    $route: {
      handler(to, from) {
        console.log('路由跳转', to, from)
        const routeNames = ['/', '/rolex', '/tudor', '/cartier', '/iwc', '/paneral', '/information']
        const routeList = ['/love', '/address', '/personage']

        const currentPath = this.$route.path

        // 检查是否在固定路径列表中
        const isFixedRoute = routeNames.includes(currentPath)

        // 检查是否在特定动态路径下（例如 /rolex/:pageUrl）
        const isDynamicRoute = currentPath.startsWith('/rolex/')

        if (routeList.includes(currentPath)) {
          this.setActiveIndex(-1) // 将 activeIndex 变为 -1
          this.setBand(null)
        } else if (isDynamicRoute) {
          this.setActiveIndex(routeNames.indexOf('/rolex')) // 固定路径下的索引
          this.setBand(this.$route.meta.brand || null)
        } else if (isFixedRoute) {
          this.setActiveIndex(routeNames.indexOf(currentPath)) // 更新 Vuex 中的 activeIndex
          this.setBand(this.$route.meta.brand || null)
        }

        // 支持移动端导航
        let index
        index = this.dataList.findIndex(item => item.router === to.path)

        if (isDynamicRoute) {
          this.currentItemName = this.$t('Rolex')
          return
        }

        if (index == -1) {
          // 不展示出来的导航
          let list = [
            { name: this.$t('Series'), router: '/sort' },
            { name: this.$t('Details'), router: '/detail' },
            { name: this.$t('Login'), router: '/login' },
            { name: this.$t('Register'), router: '/register' },
            { name: this.$t('Modify'), router: '/modify' },
            { name: this.$t('InformationDetails'), router: '/informationDetail' },
          ]
          // 只提取路径部分以进行匹配，去掉查询参数
          let currentPath = to.path.split('?')[0]
          let listIndex = list.findIndex(item => item.router === currentPath)
          this.currentItemName = list[listIndex]?.name
        } else {
          this.currentItemName = this.dataList[index]?.name || localStorage.getItem('brand')
        }
        if (this.currentItemName == 'null') {
          this.currentItemName = ''
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 绑定 Vuex 的 mutation 方法
    ...mapMutations(['setActiveIndex', 'setBand']),

    // 点击搜索
    toggleEdit() {
      this.isEditing = !this.isEditing
      if (this.isEditing) {
        this.$nextTick(() => {
          this.$refs.seachInput.focus()
        })
      }
    },
    // 搜索
    onSearch() {
      if (this.search === '') {
        this.$message.warning(this.$t('PleaseEnterYourSearch'))
        return
      }

      // 检查当前路径是否为 /sort
      if (this.$route.path !== '/sort') {
        this.$router.push({ path: '/sort', query: { search: this.search } })
      } else {
        this.$router.replace({ path: '/sort', query: { search: this.search } })
        this.$emit('seach', this.search)
      }
    },

    seachFn(val) {
      this.$emit('seach', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  background-color: #fff;
  // margin-bottom: 10px;
}
.navBox {
  height: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  // border-top: 1px solid #a1a782;
  @media (max-width: 1200px) {
    justify-content: space-around;
  }
}
.sticky {
  position: -webkit-sticky; /* 兼容性处理 */
  position: sticky;
  top: 0; /* 距离顶部的距离，根据实际情况调整 */
  z-index: 1000; /* 确保在其他内容之上显示 */
}

ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;

  li {
    cursor: pointer;
  }
}

.nav {
  width: 40vw;

  li {
    display: flex;
    align-items: center;
    // padding: 0px 5vw;
    font-size: 1.1rem;
    transition: color 0.3s; /* 添加过渡效果，使颜色变化更平滑 */
    &:hover {
      color: var(--main-color);
    }
  }

  .active {
    color: var(--main-color);
  }

  .active li {
    position: relative; /* 使下划线定位于文本下方 */
  }

  .active li::after {
    content: '';
    position: absolute;
    bottom: -25%;
    left: 0;
    width: 100%;
    height: 2px; /* 下划线的高度 */
    background-color: var(--main-color); /* 下划线的颜色 */
  }

  // 后面的小线
  .separator {
    display: inline-block;
    width: 1px;
    height: 1em;
    background-color: #a1a782;
    margin-left: 5vw;
  }
}

// 移动端
.el-dropdown {
  color: black;
  font-size: 0.8rem;
}
.custom-dropdown-menu {
  display: block;
  width: 50vw; /* Adjust as needed */
}

// 右侧
.nav-right {
  display: flex;
  color: black;
  li {
    padding: 0px 0px 0px 10px;
    font-size: 0.8rem;
    i {
      margin-right: 5px;
    }
  }
}

// 搜索框
.TeShu {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid black;
  // caret-color: white; /* 设置光标颜色为白色 */
  outline: none;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s ease;
}
.slide-enter,
.slide-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.slide-move {
  transition: all 0.5s;
}

.slide-leave-active {
  position: absolute;
}
</style>
